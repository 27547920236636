
export default {
    props: {
        isReversed: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        slotNames() {
            return Object.keys(this.$slots);
        }
    }
};
