
import FlexibleSectionMixin from '~/mixins/flexible-section';

export default {
    mixins: [
        FlexibleSectionMixin
    ],

    props: {
        richText: {
            type: String,
            default: null
        },
        image: {
            type: Array,
            default: null
        },
        imageAlignment: {
            type: String,
            default: null
        }
    },

    computed: {
        singleImage() {
            return this.image && this.image[0] ? this.image[0] : null;
        }
    }
};
